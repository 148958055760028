import { LoginForm, LoginProps } from "../components/Login";
import React from "react";
import Container from "react-bootstrap/Container";
import { checkLogin } from "../api";
import Logo from "../imgs/logo.svg";
import Row from "react-bootstrap/Row";
import "./Main.css";

export const LoginView: React.FC<LoginProps> = ({ onLogin }) => {
  const checkAndSubmit = async (email: string) => {
    if (await checkLogin()) {
      onLogin && onLogin(email);
    }
  };

  return (
    <>
      <Container className="px-5 py-5">
        <Row className="pb-5 justify-content-center">
          <img className="Login-logo" src={Logo} alt="Qnary Logo" />
        </Row>
        <Row className="justify-content-center">
          <h2>Sign In to Continue</h2>
        </Row>
      </Container>
      <Container className="Login-Box box px-5 py-5">
        <LoginForm
          onLogin={(email: string) => {
            checkAndSubmit(email);
          }}
        />
      </Container>
    </>
  );
};

export default LoginView;
