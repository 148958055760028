import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { retrieveArticleHistory } from "../api";

import { TopBar } from "../components/TopBar";
import { ArticlePost } from "../types";
import ArticleSearch from "../components/ArticleSearch";
import "../App.css";
import { getPrettyPost } from "./ContentGeneration";

interface PostCardProps {
  post: ArticlePost;
  index: number;
}

const PostCard: React.FC<PostCardProps> = ({ post, index }) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const copied_style = showCopied ? "shadow-lg" : "shadow-sm";

  // TODO: use config file for the timeout constant
  const onCopy = () => {
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  return (
    <Card className={`mt-4 ${copied_style} border-secondary`}>
      <Card.Body>
        <Card.Text className="text-break">
          {getPrettyPost(post.post_content)}
        </Card.Text>
        <Row>
          <Col>
            {/* listens to the on-click method of the enclosed button and then copies the specified text */}
            <CopyToClipboard text={post.post_content} onCopy={onCopy}>
              <button className="mr-3 btn btn-outline-primary btn-block border-0">
                Copy
              </button>
            </CopyToClipboard>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

interface ContentHistoryProps {
  articleUrl?: string;
}

function ContentHistory() {
  const { articleUrl } = useParams<ContentHistoryProps>();
  const [contentFetched, setContentFetched] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [posts, setPosts] = useState<ArticlePost[]>([]);
  const history = useHistory();

  const fetch_content = async (url: string) => {
    try {
      let fetched_posts = await retrieveArticleHistory(url);
      if (fetched_posts.length > 0) {
        setPosts(fetched_posts);
      } else {
        setPosts([]);
      }
      setContentFetched(true);
    } catch (e) {
      setContentFetched(true);
      setPosts([]);
      return;
    }
  };

  if (articleUrl && !contentFetched) {
    fetch_content(decodeURIComponent(articleUrl));
  }

  return (
    <>
      <TopBar />
      <Container className="my-5 py-5">
        <Row className="pb-4">
          <Col>
            <a className="btn btn-link btn-lg" href="/">
              &lt; BACK TO HOME
            </a>
          </Col>
          <Col>
            <a
              className="btn btn-lg btn-outline-primary float-right"
              href={`/contentgen/${articleUrl}`}
            >
              Generate Content Ideas
            </a>
          </Col>
        </Row>

        {!contentFetched || posts.length === 0 ? (
          <>
            <ArticleSearch
              title="Search History"
              onSearch={(url: string) => {
                history.push(`/history/${encodeURIComponent(url)}`);
              }}
            />
            {contentFetched && posts.length === 0 && (
              <p className="text-center">No Content Found</p>
            )}
          </>
        ) : (
          <div>
            <h2 className="text-center mb-4">Content History</h2>
            <Row className="bg-warning">
              <Col className="col-2">
                <p className="text-center mt-4 text-uppercase font-weight-bold">
                  Generated
                </p>
              </Col>
              <Col>
                <p className="text-center mt-4 text-uppercase font-weight-bold">
                  Short Content Ideas
                </p>
              </Col>
              <Col>
                <p className="text-center mt-4 text-uppercase font-weight-bold">
                  Long Content Ideas
                </p>
              </Col>
            </Row>
            {posts.reverse().map((post, index) => (
              <Row className="my-5" key={index}>
                <Col className="col-2">
                  <p className="text-center text-uppercase pt-5 mt-5 lead">
                    {/* {new Date().toLocaleDateString()} */}
                    {post.updated_at}
                  </p>
                </Col>
                <Col>
                  <PostCard post={post} index={index} />
                </Col>
                <Col>
                  <PostCard post={post} index={index} />
                </Col>
              </Row>
            ))}
          </div>
        )}
        {error && (
          <div className="p-3 m-5 bg-danger text-white text-center rounded-pill">
            Error Occured: {error}
          </div>
        )}
      </Container>
    </>
  );
}

export default ContentHistory;
