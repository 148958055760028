import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { checkLogin, login } from "../api";

export interface LoginProps {
  onLogin?: (email: string) => any;
}

export const LoginForm: React.FC<LoginProps> = ({ onLogin }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmitting(true);
    try {
      await login(email, password);
    } catch (e: any) {
      setError(e.message);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    onLogin && onLogin(email);
  };

  return (
    <Form onSubmit={handleLogin}>
      <Form.Group controlId="email">
        <Form.Label>E-mail Address</Form.Label>
        <Form.Control
          placeholder="Email"
          size="lg"
          autoFocus
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          placeholder="Password"
          size="lg"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button
        block
        size="lg"
        type="submit"
        disabled={submitting || email.indexOf("@") < 0 || password.length === 0}
      >
        {submitting ? (
          <>
            <Spinner size="sm" animation="border" role="status" />
            <span className='pl-2'>
              Signing in...
            </span>
          </>
        ) : (
          "Sign In"
        )}
      </Button>
      {error && (
        <Alert className="mt-3" variant="danger">
          {error}
        </Alert>
      )}
    </Form>
  );
};

export const LoginModal: React.FC<{}> = () => {
  const [loggedInEmail, setLoggedInEmail] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      setLoggedInEmail(await checkLogin());
    })();
  }, []);
  return (
    <Modal show={loggedInEmail === null} onHide={() => {}}>
      <Modal.Body>
        <LoginForm onLogin={setLoggedInEmail} />
      </Modal.Body>
    </Modal>
  );
};
