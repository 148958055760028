import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { useAsync } from "react-async-hook";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import ContentHistory from "./views/ContentHistory";
import Main from "./views/Main";
import LoginView from "./views/LoginView";
import ContentGeneration from "./views/ContentGeneration";
import { checkLogin } from "./api";

const check_auth = async () => {
  try {
    const email = await checkLogin();
    if (!email) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
};

const PrivateRoute = ({ Component: Component, ...rest }: any) => {
  const isAuth = useAsync(check_auth, []);

  return (
    <>
      {isAuth.loading && <div>{/* <p>Loading...</p> */}</div>}
      {!isAuth.loading && (
        <Route
          {...rest}
          render={(props) =>
            isAuth.result ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      )}
    </>
  );
};

function App() {
  const isAuth = useAsync(check_auth, []);
  const history = useHistory();

  return (
    <>
      <Switch>
        <Route exact path="/login">
          <LoginView
            onLogin={(email: string) => {
              history.push("/");
            }}
          />
        </Route>

        <PrivateRoute exact path="/" Component={Main}></PrivateRoute>

        <PrivateRoute
          exact
          path="/history/:articleUrl?"
          Component={ContentHistory}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/contentgen/:articleUrl"
          Component={ContentGeneration}
        ></PrivateRoute>
      </Switch>
    </>
  );
}

export default App;
