import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Search from "../imgs/search.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface ArticleSearchProps {
  title: string;
  onSearch: (url: string) => any;
}

const ArticleSearch: React.FC<ArticleSearchProps> = ({ title, onSearch }) => {
  const [articleUrl, setArticleUrl] = useState<string>("");

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (articleUrl.length === 0) {
      return;
    }

    onSearch(articleUrl);
  };

  return (
    <Container className="py-5 my-5 box rounded">
      <Row className="pb-5">
        <Col className="text-center">
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row className="px-5">
        <Col>
          <Form onSubmit={handleOnSubmit}>
            <InputGroup className="input-group-lg">
              <input
                type="text"
                placeholder="Article URL"
                className="form-control"
                value={articleUrl}
                onChange={(e) => setArticleUrl(e.target.value)}
              ></input>
              <InputGroup.Append>
                <Button
                  className="btn-primary"
                  type="submit"
                  disabled={articleUrl.length === 0}
                >
                  <img
                    className="ArticleSummaryForm-SearchIcon"
                    src={Search}
                    alt="Search Icon"
                  />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleSearch;
