import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "../App.css";
import { TopBar } from "../components/TopBar";
import { Footer } from "../components/Footer";
import "./Main.css";
import ArticleSearch from "../components/ArticleSearch";
import { retrieveLastNUrls } from "../api";

function Main() {
  const [urls, setUrls] = useState<string[]>([]);
  const [urlsFetched, setUrlsFetched] = useState<boolean>(false);
  const [numHistoryUrls, setNumHistoryUrls] = useState<number>(5);

  const fetch_history_urls = async (n: number) => {
    try {
      let fetched_urls = await retrieveLastNUrls(n);
      if (fetched_urls.length > 0) {
        setUrls(fetched_urls);
        setUrlsFetched(true);
      } else {
        setUrls([]);
        setUrlsFetched(true);
      }
    } catch (e) {
      setUrls([]);
      setUrlsFetched(true);
      return;
    }
  };

  if (!urlsFetched) {
    fetch_history_urls(numHistoryUrls);
  }

  const history = useHistory();

  return (
    <>
      <TopBar />
      <ArticleSearch
        title="Social Media Post Idea Generator"
        onSearch={(url: string) => {
          console.log(url);
          history.push(`/contentgen/${encodeURIComponent(url)}`);
        }}
      />
      <Container>
        <h3>History</h3>
        {!urlsFetched || urls.length === 0 ? (
          <div className="list-group list-group-flush">No history yet! :)</div>
        ) : (
          <>
            <div className="list-group list-group-flush">
              {urls.map((url, index) => (
                <a
                  className="list-group-item text-muted"
                  href={`/history/${encodeURIComponent(url)}`}
                  key={index}
                >
                  {`${url}`}
                </a>
              ))}
            </div>

            <div className="">
              <button
                className="btn btn-lg btn-light mt-3"
                onClick={() => {
                  setNumHistoryUrls(Math.min(numHistoryUrls + 5, 25));
                  setUrlsFetched(false);
                }}
              >
                View Older
              </button>
              <button
                className="btn btn-lg btn-light mt-3"
                onClick={() => {
                  history.push("/history");
                }}
              >
                Search By URL
              </button>
            </div>
          </>
        )}
      </Container>
      <Footer/>
    </>
  );
}

export default Main;
