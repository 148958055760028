import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Article, ArticlePost } from "../types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { getArticleCategories, updateArticle } from "../api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { generateSocialMediaPosts, retrieveArticle } from "../api";
import { TopBar } from "../components/TopBar";
import { Footer } from "../components/Footer";
import Select from "react-select";
import { Spinner } from "react-bootstrap";

interface PostCardProps {
  post: ArticlePost;
  index: number;
  onRegen?: (index: number) => Promise<any>;
}

const isMention = (word: string) => {
  return word[0] === "@";
};

const isHashtag = (word: string) => {
  return word[0] === "#";
};

const isQuote = (word: string) => {
  return word.includes('"');
};

const isUrl = (word: string) => {
  // credit: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url

  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(word);
};

export const getPrettyPost = (text: string) => {
  return text
    .split(" ")
    .map<React.ReactNode>((word) => {
      if (word && word.length > 0) {
        if (isHashtag(word)) {
          return <mark>{word}</mark>;
        } else if (isMention(word)) {
          return <strong className="text-danger">{word}</strong>;
        } else if (isQuote(word)) {
          return word.split("").map((char) => {
            return char === '"' ? (
              <strong className="text-danger">{char}</strong>
            ) : (
              <span>{char}</span>
            );
          });
        } else if (isUrl(word)) {
          return <strong className="text-danger">{word}</strong>;
        } else {
          return <span>{word}</span>;
        }
      }
    })
    .reduce((prev, curr) => [prev, " ", curr]);
};

// extracted from SocialMedia Posts
const PostCard: React.FC<PostCardProps> = ({ post, index, onRegen }) => {
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const copied_style = showCopied ? "border-primary" : "";
  const copy_text = showCopied ? "Copied!" : "Copy";

  const onCopy = () => {
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1000);
  };

  return (
    <Card className={`mt-4 shadow ${copied_style}`}>
      <Card.Body>
        {isRegenerating ?
          <div className="w-full text-center pb-2">
            <Spinner animation="border" role="status" />
          </div> :
          <Card.Text className="text-break">
            {getPrettyPost(post.post_content)}
          </Card.Text>
        }
        <div className="text-right">
          <Row>
            <Col className="col-md-6">
              {/* listens to the on-click method of the enclosed button and then copies the specified text */}
              <CopyToClipboard text={post.post_content} onCopy={onCopy}>
                <button className="mr-3 btn btn-outline-primary btn-block border-0">
                  {copy_text}
                </button>
              </CopyToClipboard>
            </Col>
            <Col className="col-md-6">
              <button
                className="btn btn-outline-success btn-block border-0"
                onClick={() => {
                  if (onRegen) {
                    setIsRegenerating(true);
                    onRegen(index).then(() => setIsRegenerating(false));
                  }
                }}
              >
                {isRegenerating ?
                  <>
                    <Spinner size="sm" animation="border" role="status" />
                    <span className='pl-2'>
                      ⤾ Regening...
                    </span>
                  </> :
                  <span>
                    ⤾ Regen
                  </span>
                }
              </button>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

interface ContentGenerationProps {
  articleUrl: string;
}

const DEFAULT_ID = -1;
const DEFAULT_URL = "Loading URL...";
const DEFAULT_TITLE = "Loading Title...";
const DEFAULT_EDITED_SUMMARY = "Loading Summary...";

const ContentGeneration: React.FC<{}> = () => {
  const { articleUrl } = useParams<ContentGenerationProps>();

  // TODO: use a config file for constants
  const [article, setArticle] = useState<Article>({
    id: DEFAULT_ID,
    url: DEFAULT_URL,
    title: DEFAULT_TITLE,
    original_summary: "",
    edited_summary: DEFAULT_EDITED_SUMMARY,
  });

  const [error, setError] = useState<string>("");
  const [articleFetched, setArticleFetched] = useState<boolean>(false);
  const [originalSummary, setOriginalSummary] = useState<string>("");
  const [editedSummary, setEditedSummary] = useState<string>(
    article.edited_summary
  );
  const [category, setCategory] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<string[]>([])

  const [editing, setEditing] = useState<boolean>(false);
  const [generatedOnce, setGeneratedOnce] = useState<boolean>(false);
  const [posts, setPosts] = useState<ArticlePost[]>([]);
  const [loadingAllPosts, setLoadingAllPosts] = useState<boolean>(false);

  const [summaryBeforeEdit, setSummaryBeforeEdit] = useState<string>("");
  const characterLimit = 2600;

  useEffect(() => {
    const loadArticleCategories = async () => {
      try {
        const articleCategories = await getArticleCategories();
        setCategoryOptions(articleCategories);
      } catch (e: any) {
        setError(e.message);
        return;
      }
    }
    loadArticleCategories();
  }, []);

  useEffect(() => {
    const fetch_article = async () => {
      try {
        const fetched_article = await retrieveArticle(
          decodeURIComponent(articleUrl)
        );
        setArticle({ ...fetched_article });
        setArticleFetched(true);
        setOriginalSummary(article.original_summary);
        setSummaryBeforeEdit(article.original_summary);
        setEditedSummary(article.edited_summary);
      } catch (e: any) {
        setError(e.message);
        return;
      }
    };
    fetch_article();
  }, [articleFetched]);

  // save the article every time the article updates (may want to call it less often)
  const save_article = async () => {
    if (!articleFetched) return;
    let newArticle = { ...article, edited_summary: editedSummary };
    try {
      await updateArticle(newArticle);
      // keep the local reference up to date (not using state for article)
      setArticle({ ...newArticle });
    } catch (e: any) {
      setError(e.message);
      return;
    }
  };

  const getPosts = async () => {
    try {
      setLoadingAllPosts(true);
      let newPosts = await generateSocialMediaPosts(article, category);
      setPosts(newPosts);
      setLoadingAllPosts(false);
      setGeneratedOnce(true);
    } catch (e: any) {
      setError(e.message);
    }
  };

  const regenPost = async (index: number) => {
    try {
      let newPost = (await generateSocialMediaPosts(article, category, 1))[0];
      setPosts(posts.map((post, i) => (i === index ? newPost : post)));
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <>
      <TopBar></TopBar>
      <Container className="my-5 py-5">
        <Row>
          <Col className="mx-2 col-md-7">
            <a className="btn btn-link btn-lg" href="/">
              &lt; BACK TO HOME
            </a>
            <Row className="my-5">
              <Col>
                <Row>
                  <Col>
                    <h4>Article Summary</h4>
                  </Col>
                  <Col className="text-right">
                    <button
                      type="button"
                      className="btn btn-outline-warning font-weight-bold mb-2"
                      disabled={
                        (!editing && editedSummary === originalSummary) ||
                        (editing && editedSummary === summaryBeforeEdit)
                      }
                      onClick={() => {
                        if (editing) {
                          setEditedSummary(summaryBeforeEdit);
                        } else {
                          setEditedSummary(originalSummary);
                        }
                        save_article();
                      }}
                    >
                      {editing ? "Discard Changes 🗑" : "Revert to Original"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary font-weight-bold ml-4 mb-2"
                      onClick={() => {
                        if (!editing) {
                          setSummaryBeforeEdit(editedSummary);
                        } else {
                          setEditedSummary(editedSummary);
                          save_article();
                        }
                        setEditing(!editing);
                      }}
                    >
                      {editing ? "Save 💾" : "Edit ✎"}
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5>
                      <a target="_blank" href={article.url}>
                        {article.title}
                      </a>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
            {editing ? (
              <>
                <Form.Control
                  as="textarea"
                  type="text"
                  value={editedSummary}
                  rows={10}
                  onChange={(e) => {
                    if (e.target.value.length <= characterLimit) {
                      setEditedSummary(e.target.value);
                    }
                  }}
                ></Form.Control>
                <p
                  className="float-right font-weight-light"
                  style={{
                    color:
                      editedSummary &&
                      characterLimit - editedSummary.length < 100
                        ? "red"
                        : "black",
                  }}
                >
                  {`${editedSummary.length} / ${characterLimit} characters max`}
                </p>
              </>
            ) : (
              editedSummary
                .split("\n")
                .map((par: string, index) => <p key={index}>{par}</p>)
            )}
          </Col>
          <Col className="mx-2">
            <Row className="mb-5">
              <Col>
                <a
                  className="btn btn-lg btn-outline-primary float-right"
                  href={`/history/${articleUrl}`}
                >
                  View History
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Content Ideas</h4>
              </Col>
              {generatedOnce && (
                <Col>
                  <Container>
                    <button
                      className="btn btn-primary btn-block"
                      disabled={editing || editedSummary == DEFAULT_EDITED_SUMMARY}
                      onClick={() => getPosts()}
                    >
                      {loadingAllPosts ?
                        <>
                          <Spinner size="sm" animation="border" role="status" />
                          <span className='pl-2'>
                            ⤾ Regenerating...
                          </span>
                        </> :
                        <span>
                          ⤾ Regenerate
                        </span>
                      }
                    </button>
                  </Container>
                </Col>
              )}
            </Row>
            {!generatedOnce && (
              <Container className="py-2">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  disabled={editing || editedSummary == DEFAULT_EDITED_SUMMARY}
                  onClick={() => {
                    getPosts();
                  }}
                >
                  {loadingAllPosts ?
                    <>
                      <Spinner size="sm" animation="border" role="status" />
                      <span className='pl-2'>
                        Generating Content Ideas...
                      </span>
                    </> :
                    <span>
                      Generate Content Ideas
                    </span>
                  }
                </button>
              </Container>
            )}
            <Container className="pt-2 pb-4">
              <Select 
                placeholder="Select an Article Category..."
                options={categoryOptions.map((category) => {
                  return { label: category, value: category }
                })}
                onChange={(category) => setCategory(category?.value || "")}
              />
            </Container>
            {loadingAllPosts ?
              <div className="w-full text-center">
                <Spinner animation="border" role="status" />
              </div>
              : posts.map((post, index) => (
                <PostCard
                  post={post}
                  index={index}
                  key={index}
                  onRegen={regenPost}
                />
            ))}
          </Col>
        </Row>
        {error && (
          <Row className="mt-3">
            <Col>
              <div className="p-3 m-2 bg-danger text-white text-center rounded-pill">
                Error Occurred: {error}
              </div>
            </Col>
          </Row>
        )}
      </Container>
      <Footer/>
    </>
  );
};

export default ContentGeneration;
