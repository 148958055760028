import { Article, ArticlePost } from "./types";

export async function login(email: string, password: string): Promise<string> {
  let url = new URL(`/api/auth/login`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    email?: string;
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.email as string;
}

export async function checkLogin(): Promise<string | null> {
  let url = new URL(`/api/auth/check-login`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
  });
  if (response.status === 401) {
    return null;
  } else if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    email?: string;
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.email as string;
}

export async function logout(): Promise<void> {
  let url = new URL(`/api/auth/logout`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
}

export async function retrieveArticle(articleUrl: string): Promise<Article> {
  let url = new URL(`/api/retrieve_article`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ articleUrl }),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    article?: Article;
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.article as Article;
}

export async function retrieveArticleHistory(
  articleUrl: string
): Promise<ArticlePost[]> {
  let url = new URL(`/api/retrieve_article_history`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ articleUrl }),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    article_posts?: ArticlePost[];
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.article_posts as ArticlePost[];
}

export async function updateArticle(article: Article): Promise<void> {
  let url = new URL(`/api/update_article`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(article),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
}

export async function generateSocialMediaPosts(
  article: Article,
  category: string,
  num_posts: number | null = null
): Promise<ArticlePost[]> {
  let url = new URL(`/api/generate_social_media_posts`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ article, category, num_posts }),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    article_posts?: ArticlePost[];
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.article_posts as ArticlePost[];
}

export async function retrieveLastNUrls(n: number): Promise<string[]> {
  let url = new URL(`/api/retrieve_last_n_urls`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ n }),
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    urls?: string[];
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  if (body.urls) {
    return body.urls;
  } else {
    return [];
  }
}

export async function getArticleCategories(): Promise<string[]> {
  let url = new URL(`/api/get_article_categories`, window.location.origin);
  const response = await fetch(url.toString(), {
    credentials: "include",
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(
      `An error occurred: ${response.status} ${response.statusText}`
    );
  }
  const body = await (response.json() as Promise<{
    status: string;
    error?: string;
    categories?: string[];
  }>);
  if (body.status !== "success") {
    throw new Error(body.error);
  }
  return body.categories || [];
}
