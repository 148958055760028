import React from "react";
import "../App.css";
import Container from "react-bootstrap/Container";

const footerStyle = {
  padding: "20px",
  fontSize: "14px",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
};

const phantomStyle = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
  borderBottom: "1px solid #E7E7E7",
};

export function Footer() {
  return (
    <div>
      <div style={phantomStyle} />
      <Container style={footerStyle}>{"Note: All content ideas are generated by GPT-3, an AI model created by OpenAI. Review and edit all content before posting to social media. "}</Container>
      <Container style={footerStyle}>{"Review all hashtags, @ mentions, quotes, and URL's before using content ideas. We cannot guarantee that they are all valid."}</Container>
      <Container className="py-3"></Container>
    </div>
  );
}
