import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../imgs/logo.svg";
import "../App.css";
import { logout } from "../api";

export const TopBar: React.FC<{}> = () => {
  const history = useHistory();

  const BUG_REPORT_LINK =
    "https://docs.google.com/forms/d/e/1FAIpQLSebtFfLLAMNQl2vdrc7A1tJhCiBvE1ExeIDHbTCTTTfU9Vh1A/viewform?usp=pp_url";

  const handleLogout = async () => {
    await logout();
    history.push("/login");
  };

  return (
    <Navbar className="App-Navbar px-5 justify-content-center" expand="lg">
      <Navbar.Brand href="/">
        <h1 className="d-inline m-1">Qrow</h1> by
        <img className="App-logo" src={Logo} alt="Qnary Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            className="App-NavbarLink m-4"
            target="_blank"
            href={BUG_REPORT_LINK}
          >
            Report a Bug <span className="h4">🕷</span>
          </Nav.Link>
          <Nav.Link className="App-NavbarLink m-4" href="/admin/">
            Admin
          </Nav.Link>
          <Nav.Link
            className="App-NavbarLink m-4 App-NavbarLogout"
            href="#"
            onClick={handleLogout}
          >
            Logout
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
